// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo, cable } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// require("@rails/ujs").start()
window.Rails = require("@rails/ujs")
Rails.start()

require("@rails/activestorage").start()
require("channels")
require("../libraries/hotwire-debug")
// require("trix")
// require("@rails/actiontext")

import "stylesheets/application"
import "controllers"


import LocalTime from "local-time"
LocalTime.start()


console.log("turbo")
console.log(Turbo)

document.addEventListener("turbo:load", function() {
  console.log("turbo:load")
})

document.addEventListener("turbo:submit-start", function() {
  console.log("turbo:submit-start")
  Turbo.navigator.delegate.adapter.showProgressBar();
})

document.addEventListener("turbo:submit-end", function() {
  console.log("turbo:submit-end")
  Turbo.navigator.delegate.adapter.hideProgressBar();
})


// Start Rails UJS
// Rails.start()
