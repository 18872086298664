// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

// import Flatpickr from 'stimulus-flatpickr'
// application.register('flatpickr', Flatpickr)

// // import Flatpickr
// import Flatpickr from 'stimulus-flatpickr'
//
// // Import style for flatpickr
// require("flatpickr/dist/flatpickr.css")
//
// // Manually register Flatpickr as a stimulus controller
// application.register('flatpickr', Flatpickr)
