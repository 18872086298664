// <div data-controller="geoposition">
//   <h1 data-target="hello.output"></h1>
// </div>

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { SearchControl, GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { Controller } from "stimulus"
import {MarkerIcon, MarkerIconNumber} from '../libraries/marker'

export default class extends Controller {
  static targets = [ "latitude", "longitude" ]

  connect() {
    var that = this;
    this.lat = this.data.get('lat') || 0.0
    this.long = this.data.get('long') || 0.0
    this.id = this.data.get('id');
    this.grid = JSON.parse(this.data.get('grid'));

    this.map = L.map('map').setView([this.lat, this.long], 11);

    this.layer = L.tileLayer("https:\/\/{s}.tile.openstreetmap.org\/{z}\/{x}\/{y}.png", {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a></a>',
      minZoom: 2,
      maxZoom: 18,
    }).addTo(this.map);

    this.marker = L.marker([this.lat, this.long], {
      icon: MarkerIcon.get(this.id),
      draggable: 'true'
    }).addTo(this.map);

    // --------------------------------------------------------------------------------

    this.showGrid();

    this.marker.on('dragend', function(event){
      var el = event.target;
      var position = el.getLatLng();

      that.lat = position.lat
      that.long = position.lng

      el.setLatLng(new L.LatLng(that.lat, that.long),{draggable:'true'});

      that.latitudeTarget.value = that.lat;
      that.longitudeTarget.value = that.long;
    });

    this.search = new SearchControl({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      style: 'bar'
    });
    this.map.addControl(this.search);


    //this.addMarker();
    this.map.on('click', this.onMapClick);
    this.map.on('geosearch/showlocation', function (e){
      console.log('geosearch/showlocation');
      console.log(e);

      that.lat = e.location.raw.lat;
      that.long = e.location.raw.lon;

      that.marker.setLatLng(new L.LatLng(that.lat, that.long),{draggable:'true'});

      that.latitudeTarget.value = that.lat;
      that.longitudeTarget.value = that.long;

    });
    this.map.on('geosearch/marker/dragend', function (e){
      console.log('geosearch/marker/dragend');
      console.log(e);
    });
  }

  showGrid(){
    if(this.grid){
      var that = this;
      Array.prototype.forEach.call(this.grid, function(point, index, array){
        L.marker([point.lat, point.lon], {
          // icon: MarkerIcon.get(index),
          icon: MarkerIconNumber.get(index),
          draggable: 'false'
        }).addTo(that.map);
      });
    }
  }

  onMapClick(e) {
    // e.latlng
  };

  addMarker(){
    this.marker = L.marker([this.lat, this.long], {
      icon: MarkerIcon.get(this.id),
      draggable: 'true'
    }).addTo(this.map);

    var that = this;

    this.marker.on('dragend', function(event){
      var el = event.target;
      var position = el.getLatLng();

      that.lat = position.lat
      that.long = position.lng

      el.setLatLng(new L.LatLng(that.lat, that.long),{draggable:'true'});

      that.latitudeTarget.value = that.lat;
      that.longitudeTarget.value = that.long;
    });
  }
}



// new GeoSearchControl({
//   provider: myProvider, // required
//   showMarker: true, // optional: true|false  - default true
//   showPopup: false, // optional: true|false  - default false
//   marker: {
//     // optional: L.Marker    - default L.Icon.Default
//     icon: new L.Icon.Default(),
//     draggable: false,
//   },
//   popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
//   maxMarkers: 1, // optional: number      - default 1
//   retainZoomLevel: false, // optional: true|false  - default false
//   animateZoom: true, // optional: true|false  - default true
//   autoClose: false, // optional: true|false  - default false
//   searchLabel: 'Enter address', // optional: string      - default 'Enter address'
//   keepResult: false, // optional: true|false  - default false
//   updateMap: true, // optional: true|false  - default true
// });
