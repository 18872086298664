var turboEvents = [
  "turbo:before-cache",
  "turbo:before-render",
  "turbo:before-visit",
  "turbo:click",
  "turbo:load",
  "turbo:render",
  "turbo:submit-end",
  "turbo:submit-start",
]

turboEvents.forEach(function(turboEvent) {
  document.addEventListener(turboEvent, function() {
    if (showChanges()) {
      console.log("⚡️ " + turboEvent);
      // handleChange();
    }
  });
})

function showChanges () {
  return document.documentElement.hasAttribute('data-hotwire-debug')
}

function handleChange() {
  document.querySelectorAll('turbo-frame:first-child').forEach(function(target) {
    target.setAttribute('data-turbo-frame-id', target.closest('turbo-frame').id);
  });
}

window.addEventListener("turbo:load", () => {
  document.documentElement.toggleAttribute("data-hotwire-debug");
});
