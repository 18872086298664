import L from "leaflet";


class MarkerIcon {

  static get(id) {
    return L.divIcon({
      className: `custom-div-icon order-${id}`,
      html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
             </svg>`,
      iconSize: [30, 42], // iconSize: [30, 42]
      iconAnchor: [15, 42] // iconAnchor: [15, 42]
    })
  }
}

class MarkerIconNumber {

  static get(id) {
    return L.divIcon({
      className: `custom-div-icon order-${id}`,
      html: `<div class="w-6 h-6 rounded-full bg-blue-800 relative flex items-center justify-center text-white text-sm font-bold">
                <span class="marker-number" data-index="${id}">${id}</span>
             </div>`,
      iconSize: [30, 42], // iconSize: [30, 42]
      iconAnchor: [15, 42] // iconAnchor: [15, 42]
    })
  }
}

export {MarkerIcon, MarkerIconNumber}
