// <div data-controller="geoposition">
//   <h1 data-target="hello.output"></h1>
// </div>

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { SearchControl, GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { Controller } from "stimulus"
import {MarkerIcon, MarkerIconNumber} from '../libraries/marker'

export default class extends Controller {
  static targets = [ "latitude", "longitude" ]

  connect() {
    var that = this;
    this.lat        = this.data.get('lat') || 0.0
    this.long       = this.data.get('long') || 0.0
    this.id         = this.data.get('id');
    this.grid_url   = this.data.get('gridurl');
    // this.grid_array = JSON.parse(this.data.get('grid'));
    this.grid_array = JSON.parse(document.getElementById("preset_grid_array").value);
    this.grid_array_markers = []

    this.map = L.map('map').setView([this.lat, this.long], 11);

    this.layer = L.tileLayer("https:\/\/{s}.tile.openstreetmap.org\/{z}\/{x}\/{y}.png", {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a></a>',
      minZoom: 2,
      maxZoom: 18,
    }).addTo(this.map);

    this.marker = L.marker([this.lat, this.long], {
      icon: MarkerIcon.get(this.id),
      draggable: 'true'
    }).addTo(this.map);

    this.marker.on('dragend', function(event){
      var el = event.target;
      var position = el.getLatLng();

      that.lat = position.lat
      that.long = position.lng

      el.setLatLng(new L.LatLng(that.lat, that.long),{draggable:'true'});

      that.latitudeTarget.value = that.lat;
      that.longitudeTarget.value = that.long;

      that.handleChange();
    });

    this.search = new SearchControl({
      provider: new OpenStreetMapProvider(),
      showMarker: false,
      style: 'bar'
    });
    this.map.addControl(this.search);

    this.map.on('geosearch/showlocation', function (e){
      console.log('geosearch/showlocation');
      console.log(e);

      that.lat = e.location.raw.lat;
      that.long = e.location.raw.lon;

      that.marker.setLatLng(new L.LatLng(that.lat, that.long),{draggable:'true'});

      that.latitudeTarget.value = that.lat;
      that.longitudeTarget.value = that.long;

      that.handleChange();
    });

    if(this.grid_array.length > 0){
      this.showGrid();
    }else{
      this.handleChange();
    }

  }

  showGrid(){
    var that = this;

    Array.prototype.forEach.call(this.grid_array_markers, function(marker, index, array){
      that.map.removeLayer(marker)
    });

    Array.prototype.forEach.call(this.grid_array, function(point, index, array){
      if(index > 0){
        let marker = L.marker([point.lat, point.lon], {
          icon: MarkerIconNumber.get(index),
          draggable: 'false'
        }).addTo(that.map);

        marker.on('dragend', function(event){
          let el = event.target;
          let position = el.getLatLng();

          el.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'});
          let index = el._icon.querySelectorAll('.marker-number')[0].dataset.index

          that.grid_array[index] = {lat: position.lat, lon: position.lng, index: index}
          document.getElementById("preset_grid_array").value = JSON.stringify(that.grid_array)
        });

        that.grid_array_markers.push(marker);
      }

    });
  }

  handleChange(){
    var that = this;
    var grid_dim_el = document.getElementById("preset_grid_dim");
    var grid_step_el = document.getElementById("preset_grid_step");

    let params = {
      "latitude": this.lat,
      "longitude": this.long,
      "grid_dim": grid_dim_el.value,
      "grid_step": grid_step_el.value,
    };

    let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    let url = this.grid_url + '?' + query;

    Rails.ajax({
      url: url,
      type: "get",
      data: "",
      success: function(data) {
        that.grid_array = data;
        that.showGrid()
        document.getElementById("preset_grid_array").value = JSON.stringify(that.grid_array)
      },
      error:function(data) {
        console.log(data)
      }
    })
  }
}



// function getMetaValue(name) {
//   const element = document.head.querySelector(`meta[name="${name}"]`)
//   return element.getAttribute("content")
// }
//
// headers: {
//   "X-CSRF-Token": getMetaValue("csrf-token"),
//     "Content-Type": "application/json"
// },


// new GeoSearchControl({
//   provider: myProvider, // required
//   showMarker: true, // optional: true|false  - default true
//   showPopup: false, // optional: true|false  - default false
//   marker: {
//     // optional: L.Marker    - default L.Icon.Default
//     icon: new L.Icon.Default(),
//     draggable: false,
//   },
//   popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
//   maxMarkers: 1, // optional: number      - default 1
//   retainZoomLevel: false, // optional: true|false  - default false
//   animateZoom: true, // optional: true|false  - default true
//   autoClose: false, // optional: true|false  - default false
//   searchLabel: 'Enter address', // optional: string      - default 'Enter address'
//   keepResult: false, // optional: true|false  - default false
//   updateMap: true, // optional: true|false  - default true
// });
