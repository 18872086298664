// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="geoposition">
//   <h1 data-target="hello.output"></h1>
// </div>

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { SearchControl, GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { Controller } from "stimulus"
import {MarkerIcon, MarkerIconNumber} from '../libraries/marker'

export default class extends Controller {
  static targets = [ "latitude", "longitude" ]

  connect() {
    var that = this;
    this.lat = this.data.get('lat') || 0.0
    this.long = this.data.get('long') || 0.0
    this.mapid = this.data.get('mapid');
    this.grid = JSON.parse(this.data.get('grid'));

    this.map = L.map(`map-${this.mapid}`).setView([this.lat, this.long], 11);

    this.layer = L.tileLayer("https:\/\/{s}.tile.openstreetmap.org\/{z}\/{x}\/{y}.png", {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a></a>',
      minZoom: 2,
      maxZoom: 18,
    }).addTo(this.map);

    this.marker = L.marker([this.lat, this.long], {
      icon: MarkerIcon.get(this.mapid),
      draggable: 'true'
    }).addTo(this.map);
  }

}



// new GeoSearchControl({
//   provider: myProvider, // required
//   showMarker: true, // optional: true|false  - default true
//   showPopup: false, // optional: true|false  - default false
//   marker: {
//     // optional: L.Marker    - default L.Icon.Default
//     icon: new L.Icon.Default(),
//     draggable: false,
//   },
//   popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
//   maxMarkers: 1, // optional: number      - default 1
//   retainZoomLevel: false, // optional: true|false  - default false
//   animateZoom: true, // optional: true|false  - default true
//   autoClose: false, // optional: true|false  - default false
//   searchLabel: 'Enter address', // optional: string      - default 'Enter address'
//   keepResult: false, // optional: true|false  - default false
//   updateMap: true, // optional: true|false  - default true
// });
